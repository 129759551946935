<template>
  <v-card
    class="pa-4 dialog-content"
    :style="{
      borderRadius: $vuetify.breakpoint.smAndDown ? '0px !important' : '',
      height: '100%',
    }"
  >
    <v-card-title class="modal-title">
      <div class="d-flex justify-space-between w-100">
        <div class="d-flex">
          <v-img
            width="28"
            height="28"
            :src="require('@/assets/ttuToken.svg')"
          />
          <!-- <span class="subtitle pl-2">{{ $t("profile.myQr") }}</span> -->
          <block-text
            color="trolleyGrey"
            class="pl-2 text-red"
            :text="$t('dialog.ttuDebit')"
            type="headline"
          />
        </div>
        <ph-x
          :size="24"
          class="pointer"
          :color="baseFontColor"
          @click="close"
        />
      </div>
    </v-card-title>

    <v-card-text class="justify-center align-center">
      <div class="text-center">
        <div class="mt-8">
          <block-text :text="$t('dialog.scanQRcodeFromUser')" type="title" />
        </div>
        <div class="mt-8 d-flex text-center justify-center">
          <ph-qr-code :size="120" color="#C0BFBF" v-if="!isScanning" />
          <qrcode-stream
            v-else
            class="qrcode-wrapper"
            @decode="onDecode"
            @init="onInit"
          />
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      class="d-flex flex-column text-center justify-space-between mt-4"
    >
      <v-btn
        :color="$vuetify.theme.dark ? '#fff' : '#222'"
        large
        plain
        class="body-1 bold px-7 py-2 primary-btn"
        depressed
        outlined
        rounded
        @click="isScanning = true"
      >
        <span>{{ $t("dialog.startScanning") }}</span>
      </v-btn>
      <v-btn
        :color="$vuetify.theme.dark ? '#fff' : '#222'"
        large
        plain
        class="body-1 bold py-2 text-center"
        depressed
        rounded
        @click="close"
      >
        <span>{{ $t("cancel") }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PhQrCode, PhWallet, PhArrowLeft, PhX } from "phosphor-vue";
import { QrcodeStream } from "vue-qrcode-reader";
export default {
  name: "CashierScan",
  components: {
    PhQrCode,
    PhWallet,
    PhX,
    PhArrowLeft,
    QrcodeStream,
  },
  data() {
    return {
      result: "",
      error: "",
      isScanning: false,
    };
  },
  computed: {
    baseFontColor() {
      return this.$vuetify.theme.dark ? "#fff" : "#222";
    },
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    async onDecode(result) {
      this.isScanning = false;
      try {
        const qrCodeUrl = new URL(result);
        const ttuUserId = qrCodeUrl.searchParams.get("ttuid");
        const username = qrCodeUrl.searchParams.get("username");
        const receiverExists = await this.$store.dispatch(
          "wallet/getTransferReceiverByTtuId",
          ttuUserId
        );
        if (receiverExists) {
          await this.$store.dispatch(
            "wallet/setTransferReceiverUsername",
            username
          );
          this.result = result;
          this.$emit("setStep", 1);
        } else {
          throw new Error("Not recognized receiver");
        }
      } catch (e) {
        console.log(e, result);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn--outlined {
  border: 1px solid var(--v-silver-base) !important;
}
.modal-title {
  color: var(--v-trolleyGrey-base) !important;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}
.qrcode-wrapper {
  width: 250px;
  height: 250px;
  max-width: 500px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dialog-content {
    position: fixed;
    width: 100%;
    overflow-y: auto;
  }
}
</style>
